<template>
  <div class="notfound-page flex flex-col justify-center items-center w-2/3 max-w-40">
      <h1>Oops, it looks like the page you're looking for doesn't exist.</h1>
    <button @click="onBack" class="bg-primary text-white w-2/4 rounded-3xl py-3 mt-5 mx-auto text-base border border-primary focus:outline-none hover:bg-white hover:text-primary hover:shadow-lg">
      Back
    </button>
  </div>
  
</template>
<script>
import { useStore } from 'vuex';
import { ref , computed} from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default ({
  name: 'NotFound',
  props: {
    message: {
      type: String,
      required: false,
    },
  },
  setup() {
    const router = useRouter()

    function onBack(e){
      e.preventDefault()
      router.push({ name: 'Orders'})
    }

    return {
      onBack
    }
  },
});

</script>

<style lang="scss" scoped>
  .notfound-page {
    margin:60px auto;
  }
</style>
